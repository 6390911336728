import React  from 'react';
import TeaserVid from '../img/VVN Emblem Reveal.mp4';
import { BrowserView, MobileView } from 'react-device-detect';

export default () => {
  return (
    <React.Fragment>
    <div className="wrap">
    </div>
      <BrowserView>
        <video autoPlay="autoplay" playsInline muted loop="loop" id="myVideo" className="landingVid2">
          <source src={TeaserVid} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </BrowserView>
      <MobileView>
        <video autoPlay="autoplay" playsInline muted loop="loop" id="myVideo" className="landingVidMobile">
          <source src={TeaserVid} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </MobileView>
    </React.Fragment>
  )
    
};
