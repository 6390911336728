import React from 'react';

export default () => {
  return (
    <div>

      <div className="wrap centerContent">
        <div className="text-light text-center">
          <div className="wrapTitle centerContent anim">
            <h2 className="cinzel text-center pageTitle text-light ">Contact</h2>
          </div>
          <p className="text-light  d-block">
            iam@vvnzquan.com
          </p>
        </div>
      </div>
    </div>
  );
};
